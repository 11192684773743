<template>
        <div>
                <v-chip class="mr-4"
                v-if="request.OfferCount">
                    <date-user-tooltip
                    :date="request.LastOffer">
                        <span class="d-none d-md-inline">Предложения</span>
                        {{request.OfferCount}}
                    </date-user-tooltip>
                </v-chip>
                <v-chip class="mr-4"
                v-if="request.ShowCount"
                color="light-blue lighten-1">
                    <date-user-tooltip
                    :date="request.LastShow">
                        <span class="d-none d-md-inline">Показы</span>
                        {{request.ShowCount}}
                    </date-user-tooltip>
                </v-chip>
                <v-chip class="mr-4"
                v-if="request.NegotiationCount"
                color="yellow lighten-1">
                    <date-user-tooltip
                    :date="request.LastDeal">
                        <span class="d-none d-md-inline">Переговоры</span>
                        {{request.NegotiationCount}}
                    </date-user-tooltip>
                </v-chip>
                <v-chip class="mr-4"
                v-if="request.DealCount"
                color="green accent-4">
                    <date-user-tooltip
                    :date="request.LastDeal">
                        <span class="d-none d-md-inline">Сделки</span>
                        {{request.DealCount}}
                    </date-user-tooltip>
                </v-chip>



        </div>
</template>

<style scoped>
.text-no-wrap {
  word-break: normal !important;
}

.word-break-normal {
  word-break: normal !important;
}
</style>



<script>
import dateUserTooltip from '@/components/date-user-tooltip.vue'


export default {
    name: 'request-heading',
    components: {
        dateUserTooltip,

    },
    props: {
        request: {
            type: Object,
        },
    },
    data () {
        return {
            nextCall: {},
            contacts: [],
            doRequest: false,
            checkResponse: function (response) {
                return (response.contacts && response.contacts.length > 0);
            },
        }
    },
    computed: {
        showContacts: function () {
            return (this.$checkPermissions('view_Third-Party_Request') || this.request.User_ID == this.$store.state.user.id )
        },
        heading: function () {
            let result = '';

            result += this.contractType ? (this.contractType + ' ') : '';
            result += this.destination ? (this.destination + ' ') : '';
            result = result.trim();

            return result || 'нет данных';

        },
        square: function () {
            let result = '';
            if ( this.request.MinSquare ) {
                result += ' от ' + this.request.MinSquare;
            }
            if ( this.request.MaxSquare ) {
                result += ' до ' + this.request.MaxSquare;
            }
            if ( result ) {
                result = result + ' м²'
            }
            return result;
        },
        destination: function () {
            let result = '';

            let destinations = {
                1: 'офис',
                2: 'продуктовый магазин',
                4: 'склад',
                8: 'производство',
                16: 'кафе, ресторан',
                32: 'банк',
                64: 'магазин',
                128: 'салон',
                256: 'медцентр',
                512: 'гостиницу'
            }

            if ( this.request.slDestination && destinations[this.request.slDestination]) {
                result = 'под ' + destinations[this.request.slDestination];
            }
            return result;
        },

        contractType: function () {
            let result = '';

            if ( this.request.slNeedContractType && (this.request.slNeedContractType & 8 ) > 0) {
                result = 'покупка';
            } else if ( this.request.slNeedContractType && (this.request.slNeedContractType & 7 ) > 0) {
                result = 'аренда'
            }
            return result;
        },

        contract: function () {
            let result = '';

            let contracts = {
                1: 'прямая аренда',
                3: 'кроме ДСД',
                7: 'аренда',
                8: 'покупка',
            }

            if ( this.request.slNeedContractType && contracts[this.request.slNeedContractType]) {
                result = contracts[this.request.slNeedContractType];
            }
            return result;
        },

        price: function () {
            let result = '';

            let contracts = {
                100: ' USD',
                101: ' УЕ',
                102: ' EUR',
                103: ' руб.',
            }


            if (this.request.MonthPrice) {
                result += 'до ' + new Intl.NumberFormat().format(this.request.MonthPrice) + contracts[this.request.slCurrency] + ' в мес.';
            }

            if (this.request.MeterPrice) {
                if (result) {
                    result += ', ';
                }
                result += 'до ' + new Intl.NumberFormat().format(this.request.MeterPrice) + contracts[this.request.slCurrency] + ' за м²'
            }

            return result;
        },

    },

    methods: {
        nextCallChanged: function (data) {
            this.nextCall = data;
            this.$emit('next-call-changed');
        },
        onLoadData: function (data) {
            this.contacts = [];
            this.doRequest = false;
            if ( data && this.checkResponse(data) ) {
                this.contacts = data.contacts;
            }
        },
    },

    created() {
        this.nextCall.Request_ID = this.request.ID;
        this.nextCall.NextCallDate = this.request.NextCallDate;
        this.nextCall.NextCallComment = this.request.NextCallComment;
        this.nextCall.User_Upd = this.request.NextCall_User_Upd;
        this.nextCall.DateUpd = this.request.NextCall_DateUpd;
        this.doRequest = true;
    },

}
</script>

