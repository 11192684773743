<template>
    <v-container>

        <search-by-id
        :form-data="searchFormData"
        :do-request="doObjectSearch"
        @loading-complete="doObjectSearch=false"
        @refresh-data="doObjectSearch=true"
        />

        <v-tabs
        v-if="currentId"
        class="mt-4">

            <v-tab>Действия</v-tab>

            <v-tab-item>
                <offers-list
                :do-request="true"
                :search-params="offerSearchParams"
                />
            </v-tab-item>

        </v-tabs>

    </v-container>
</template>

<script>
import searchById from '@/components/search-by-id.vue'
import offersList from '@/modules/search/offers-list.vue'

export default {
    name: 'idSearch',
    components: { 
      searchById,
      offersList
    },
    data () {
      return {
        doObjectSearch: true,
      }
    },
    computed: {
        currentId: function() {
            return (+this.$route.params.id || undefined)
        },
        searchFormData: function() {
            return {
                id: this.currentId,
                type: 'object'
            }
        },
        offerSearchParams: function() {
            return {
                square_id: this.currentId,
            }
        },
    },
}
</script>