<template>
    <v-row>
        <v-chip
        v-if="user_name"
        class="ma-2"
        close
        @click:close="setFields({'user_id': 0})"
        >
        {{user_name}}
        </v-chip>

        <search-chips-mask
        name="status"
        :value="params.status"
        :options="searchOptions.status.options"
        @setFields="setFields"
        />

        <v-chip
        v-if="params.contract == 1"
        class="ma-2"
        close
        @click:close="setFields({'contract': 0})"
        >
        Аренда
        </v-chip>

        <v-chip
        v-if="params.contract == 2"
        class="ma-2"
        close
        @click:close="setFields({'contract': 0})"
        >
        Продажа
        </v-chip>

        <search-chips-mask
        name="destination"
        :value="params.destination"
        :options="searchOptions.destination.options"
        @setFields="setFields"
        />

        <v-chip
        v-if="squareRange"
        class="ma-2"
        close
        @click:close="setFields({'min_square': 0, 'max_square': 0})"
        >
        {{squareRange}}
        </v-chip>

        <v-chip
        v-if="priceRange"
        class="ma-2"
        close
        @click:close="setFields({'min_price': 0, 'max_price': 0})"
        >
        {{priceRange}}
        </v-chip>

        <v-chip
        v-if="+params.tax_office"
        class="ma-2"
        close
        @click:close="setFields({'tax_office': 0})"
        >
        ИФНС № {{params.tax_office}}
        </v-chip>

        <v-chip
        v-if="+params.separate"
        class="ma-2"
        close
        @click:close="setFields({'separate': 0})"
        >
        С отдельным входом
        </v-chip>

        <v-chip
        v-if="+params.shows_min"
        class="ma-2"
        close
        @click:close="setFields({'shows_min': 0})"
        >
        С показами
        </v-chip>

        <v-chip
        v-if="+params.negotiations_min"
        class="ma-2"
        close
        @click:close="setFields({'negotiations_min': 0})"
        >
        Переговоры
        </v-chip>

        <v-chip
        v-if="+params.deals_min"
        class="ma-2"
        close
        @click:close="setFields({'deals_min': 0})"
        >
        Со сделками
        </v-chip>

        <v-chip
        v-if="+params.time_to_call"
        class="ma-2"
        close
        @click:close="setFields({'time_to_call': 0})"
        >
        Пора звонить
        </v-chip>

        <v-chip
        v-if="params.search_string"
        class="ma-2"
        close
        @click:close="setFields({'search_string': ''})"
        >
        Текст: {{params.search_string}}
        </v-chip>


        <v-chip
        v-if="dateRange(params.insert_date_from, params.insert_date_to)"
        class="ma-2"
        close
        @click:close="setFields({'insert_date_from': 0, 'insert_date_to': 0})"
        >
        Дата создания: {{dateRange(params.insert_date_from, params.insert_date_to)}}
        </v-chip>

        <v-chip
        v-if="dateRange(params.last_change_date_from, params.last_change_date_to)"
        class="ma-2"
        close
        @click:close="setFields({'last_change_date_from': 0, 'last_change_date_to': 0})"
        >
        Последнее изменение: {{dateRange(params.last_change_date_from, params.last_change_date_to)}}
        </v-chip>

        <v-chip
        v-if="dateRange(params.last_offer_date_from, params.last_offer_date_to)"
        class="ma-2"
        close
        @click:close="setFields({'last_offer_date_from': 0, 'last_offer_date_to': 0})"
        >
        Последнее предложение: {{dateRange(params.last_offer_date_from, params.last_offer_date_to)}}
        </v-chip>

        <v-chip
        v-if="dateRange(params.last_show_date_from, params.last_show_date_to)"
        class="ma-2"
        close
        @click:close="setFields({'last_show_date_from': 0, 'last_show_date_to': 0})"
        >
        Последний показ: {{dateRange(params.last_show_date_from, params.last_show_date_to)}}
        </v-chip>

        <v-chip
        v-if="dateRange(params.last_deal_date_from, params.last_deal_date_to)"
        class="ma-2"
        close
        @click:close="setFields({'last_deal_date_from': 0, 'last_deal_date_to': 0})"
        >
        Последняя сделка: {{dateRange(params.last_deal_date_from, params.last_deal_date_to)}}
        </v-chip>

        <v-chip
        v-if="dateRange(params.last_user_change_date_from, params.last_user_change_date_to)"
        class="ma-2"
        close
        @click:close="setFields({'last_user_change_date_from': 0, 'last_user_change_date_to': 0})"
        >
        Смена ответственного: {{dateRange(params.last_user_change_date_from, params.last_user_change_date_to)}}
        </v-chip>

    </v-row>
</template>

<script>
import searchChipsMixin from '@/mixins/search-chips-mixin.js'
import {requestSearchOptions} from '@/components/js/searchOptions.js'
import searchChipsMask from './search-chips-mask.vue'

export default {
    name: 'request-search-chips',
    components: {
        searchChipsMask,
    },
    mixins: [
        searchChipsMixin,
    ],
    data () {
        return {
            searchOptions: requestSearchOptions,
        }
    },
    computed: {
        user_id: function () {
            return this.params.user_id;
        },
        squareRange: function () {
            return this.range(this.params.min_square,this.params.max_square,'Площадь','м²');
        },
        priceRange: function () {
            return this.range(this.params.min_price,this.params.max_price,'Цена','р.');
        },
    },

    methods: {

        getUsername: function (id) {
            if ( !id || +id == 0) {
                this.user_name = ''
            } else if (id == -1) {
                this.user_name = 'С ответственным'
            } else if (id == -2) {
                this.user_name = 'Без ответственного'
            } else if (id > 0){
                this.$store.dispatch("load_user_data").then(() => {
                    this.user_name = 'Ответственный ' + this.$store.state.staffFullNameList[id].shortname;
                });
            }
        },

    },

    watch: {
        user_id(new_id) {
            this.getUsername(new_id);
        }
    },

    created() {
        this.getUsername(this.user_id);
    }

}
</script>
