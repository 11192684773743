// This class is designed to use fields from the [MEGAPOLIS].[dbo].[mega2_Squares] table

module.exports = class Square {
    constructor(o) {
        this.ID = o.ID;
        this.FirmBuilding_id = o.FirmBuilding_id;
        this.Square = o.Square;
        this.Price = o.Price;
        this.MeterPrice = o.MeterPrice;
        this.RubPrice = o.RubPrice;
        this.slCurrency = o.slCurrency;
        this.ExchangeRate = o.ExchangeRate;
        this.PriceInclusion = o.PriceInclusion;
        this.slContractType = o.slContractType;
        this.Floor = o.Floor;
        this.slDestination = o.slDestination;
        this.slPlanType = o.slPlanType;
        this.isWC = o.isWC;
        this.isSeparate = o.isSeparate;
        this.RoomCount = o.RoomCount;
        this.RoomWindowCount = o.RoomWindowCount;
        this.CarCount = o.CarCount;
        this.MGTSCount = o.MGTSCount;
        this.isINet = o.isINet;
        this.slGuardType = o.slGuardType;
        this.slDecorType = o.slDecorType;
        this.isVentilation = o.isVentilation;
        this.slConditionType = o.slConditionType;
        this.isFree = o.isFree;
        this.ReadyDate = o.ReadyDate;
        this.slSquareState = o.slSquareState;
        this.About = o.About;
        this.User_Ins = o.User_Ins;
        this.DateIns = o.DateIns;
        this.User_Upd = o.User_Upd;
        this.DateUpd = o.DateUpd;
        this.User_Del = o.User_Del;
        this.DateDel = o.DateDel;
        this.InfoDel = o.InfoDel;
        this.ElectricPower = o.ElectricPower;
        this.isFurniture = o.isFurniture;
        this.RateManager = o.RateManager;
        this.isHeat = o.isHeat;
        this.ceilingHeight = o.ceilingHeight;
        this.supplementary = o.supplementary;

        // From firm_building table
        this.Firm_ID = o.Firm_ID;
        this.Building_ID = o.Building_ID;
        this.User_ID = o.User_ID;
        this.CallDate = o.CallDate;
        this.ComissionValue = o.ComissionValue;
        this.isNoCian = o.isNoCian;
        this.isRemoteShow = o.isRemoteShow;


    }

    get id() {
        if (this.ID) {
            return this.ID;
        } else {
            return '';
        }
    }

    get status() {
        return this.slSquareState == 110;
    }

    get squareString() {
        if (this.Square) {
            return this.Square + ' м²';
        } else {
            return '';
        }
    }

    get priceString() {
        if (this.RubPrice) {
            return new Intl.NumberFormat().format(this.RubPrice) + ' р.';
        } else {
            return '';
        }
    }

    get floorString() {
        if (!this.Floor || this.Floor == 0) {
            return '';
        } else if (this.Floor == -2) {
            return 'подвал';
        } else if (this.Floor == -1) {
            return 'цоколь';
        } else {
            return this.Floor + '-й этаж';
        }
    }

    get contractString() {
        if (!this.slContractType) {
            return 'НЕТ ДОГОВОРА';
        } else  if (this.slContractType == 8) {
            return 'продажа';
        } else {
            return 'аренда';
        }
    }

    get shortTitle() {
        let result = this.contractString;

        if(this.slPlanType == 32) {
            result += (' здания')
        } else if (!this.slDestination) {
            result += (' НЕТ НАЗНАЧЕНИЯ')
        } else if (this.slDestination == 1 || this.slDestination == 33) {
            result += (' офиса')
        } else if (this.slDestination == 2 || this.slDestination == 64 || this.slDestination == 66) {
            result += (' магазина')
        } else if (this.slDestination == 4) {
            result += (' склада')
        } else {
            result += (' помещения')
        }

        if(this.squareString){
            result += (' ' + this.squareString);
        }

        if(this.floorString){
            result += (', ' + this.floorString);
        }

        result = result && result[0].toUpperCase() + result.slice(1);

        return result;
    }



};
