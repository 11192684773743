<template>
  <v-container>
    <v-row>
        <v-col>
            <div
            v-if="building.Square">
                Площадь здания {{building.Square}} м²
            </div>
            <div
            v-if="building.taxInspectorate">
                ИФНС № {{building.taxInspectorate}}
            </div>
            <div
            v-if="building.isWarehouse || building.isElevator">
                <span
                v-if="building.isWarehouse"
                class="mr-4">
                    <v-icon
                    small
                    left>
                        mdi-umbrella
                    </v-icon>
                    есть склад
                </span>
                <span
                v-if="building.isElevator">
                    <v-icon
                    small
                    left>
                        mdi-elevator-passenger-outline
                    </v-icon>
                    есть лифт
                </span>
            </div>
            <div
            v-if="building.About"
            class="text--secondary">
                {{building.About}}
            </div>
            <div class="mt-2">
                <date-ins-change-del-tooltip time :item="building"/>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import dateInsChangeDelTooltip from '@/components/date-ins-change-del-tooltip.vue'
    export default {
        name: 'building-supplementary',
        components: {
            dateInsChangeDelTooltip,
        },
        props: {
            building: {
                type: Object,
            },
        },
        data () {
            return {}
        },
    }
</script>

