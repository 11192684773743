<template>
    <v-chip
    v-if="id"
    small
    class="mr-2"
    color="primary">
        <staff-name :id='id'/>
    </v-chip>
</template>

<script>
import staffName from '@/components/staff-name.vue'

export default {
    name: 'staff-name-chip',
    components: {
        staffName
    },
    props: {
        id: {
            type: Number,
            default: 0
        },
    },
    data () {
      return {}
    },
}
</script>