<template>
<!--
    To change default activator slot:

    <building-main-menu :buildingId="item.id">
        <template v-slot:activator="{ on, attrs }">
            <span
            v-bind="attrs"
            v-on="on">
            {{item.id}}
            </span>
        </template>
    </building-main-menu>
-->

    <v-menu
    v-if="buildingId"
    open-on-hover
    bottom
    offset-y
    >
        <template v-slot:activator="slotData">
            <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotData" />
            <v-chip
            v-else
            v-on="slotData.on"
            class="mr-2">
                <v-icon>
                    mdi-home
                </v-icon>
            </v-chip>
        </template>

        <v-list>
            <v-list-item
            target="_blank"
            :href="'/building/' + buildingId">
                <v-list-item-title>
                    <v-icon left>
                        mdi-link-variant
                    </v-icon>
                    Здание № {{buildingId}}
                </v-list-item-title>
            </v-list-item>


            <v-list-item
            target="_blank"
            :href="'http://megapolis.mega-realty.ru/proxy.htm?type=building&id=' + buildingId">
                <v-list-item-title>
                    <v-icon left>
                        mdi-link-variant
                    </v-icon>
                    База Мегаполис
                </v-list-item-title>
            </v-list-item>

            <v-list-item
            target="_blank"
            :href="'https://www.mega-realty.ru/_b' + buildingId + '.htm'">
                <v-list-item-title>
                    <v-icon left>
                        mdi-web
                    </v-icon>
                    mega-realty.ru
                </v-list-item-title>
            </v-list-item>

            <v-list-item
            target="_blank"
            :href="'/object-uploads/building/' + buildingId">
                <v-list-item-title>
                    <v-icon left>
                        mdi-camera-outline
                    </v-icon>
                    Загрузки
                </v-list-item-title>
            </v-list-item>

            <v-list-item @click="openAddPhotoDialog">
                <v-list-item-title>
                    <v-icon left>
                        mdi-camera-plus-outline
                    </v-icon>
                    Добавить фото
                </v-list-item-title>
            </v-list-item>
            <entity-dialog
            max-width="500px"
            :id="buildingId"
            type="building"
            :show="showAddPhotoDialog"
            @close-dialog="showAddPhotoDialog = false">
                <template v-slot:default>
                    <media-upload
                    :id="buildingId"
                    type="building"
                    @upload-complete="showAddPhotoDialog = false"/>
                </template>
            </entity-dialog>

            <v-list-item @click="openYoutubeDialog">
                <v-list-item-title>
                    <v-icon left>
                        mdi-youtube
                    </v-icon>
                    YouTube
                </v-list-item-title>
            </v-list-item>
            <entity-dialog
            :id="buildingId"
            type="building"
            :show="showYoutubeDialog"
            @close-dialog="showYoutubeDialog = false">
                <template v-slot:default>
                    <youtube-links
                    :id="buildingId"
                    type="building"/>
                </template>
            </entity-dialog>


        </v-list>
    </v-menu>
</template>

<script>
    import entityDialog from '@/components/entity-dialog.vue'
    import youtubeLinks from '@/components/youtube-links.vue'
    import mediaUpload from '@/components/media-upload.vue'

    export default {
        name: 'building-main-menu',
        components: {
            entityDialog,
            youtubeLinks,
            mediaUpload
        },
        props: {
            buildingId: {
                type: Number,
            },
        },
        data () {
            return {
                showYoutubeDialog: false,
                showAddPhotoDialog: false,
            }
        },
        methods: {
            openAddPhotoDialog () {
                setTimeout(() => this.showAddPhotoDialog = true);
            },
            openYoutubeDialog () {
                setTimeout(() => this.showYoutubeDialog = true);
            },
        },
    }
</script>