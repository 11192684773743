<template>
    <v-container>
        <v-row>
            <v-col>
                <span class="text-h5">Ключи доступа</span>
            </v-col>
        </v-row>

        <v-row
        class="px-3 py-6">
            <select-entity-dialog
            @on-search="search"
            path="/access-keys/"
            :button-text="buttonText">
            </select-entity-dialog>
        </v-row>

        <data-loader
        url="/api/access-keys/list/"
        :form-data="searchFormData"
        :do-request="doRequest"
        :check-response="checkResponse"
        @loading-complete="onLoadData">
            <template v-slot:default>
                <v-card
                v-if="searchResults.building">
                    <v-card-title>
                        <building-heading
                        class="my-4"
                        :building="searchResults.building">
                        </building-heading>
                    </v-card-title>
                    <v-card-text
                    v-if="searchResults.fb">
                        <span class="text-subtitle-1">Собственники в здании:</span>
                        <v-list
                        flat>
                            <access-keys-fb
                            v-for="item in searchResults.fb"
                            :key="item.ID"
                            :fb="item"></access-keys-fb>
                        </v-list>
                    </v-card-text>
                </v-card>
            </template>
        </data-loader>

    </v-container>
</template>

<script>
import buildingHeading from '@/components/entities/building/building-heading.vue'
import accessKeysFb from './components/access-keys-fb.vue'
import selectEntityDialog from '@/components/select-entity-dialog.vue'
import dataLoader from '@/components/data-loader.vue'

export default {
    name: 'accessKeysList',

    components: {
        buildingHeading,
        accessKeysFb,
        selectEntityDialog,
        dataLoader
    },
    data () {
        return {
            doRequest: false,
            searchFormData: {},
            searchResults: {},
            buttonText: 'Выбрать объект',

        }
    },
    //computed: {},
    methods: {
        checkResponse: function (response) {
            return (response.searchResults && response.searchResults.building && response.searchResults.building.ID);
        },

        onLoadData: function (data) {
            this.searchResults = {};
            this.doRequest = false;
            if ( data && this.checkResponse(data) ) {
                this.searchResults = data.searchResults;
            }
            this.buttonText = 'Выбрать другой объект';

        },

        search: function (data) {
            this.searchFormData = data;
            this.doRequest = true;
        },

        updateFbKeysList: function (id, keys) {
            if ( keys && id && this.searchResults && this.searchResults.fb && id in this.searchResults.fb ) {
                this.searchResults.fb[id].keys = keys;
            }
        },
    },
    created() {
        this.$eventBus.$on('update-fb-access-keys-list', this.updateFbKeysList);
    },

}
</script>