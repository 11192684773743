<template>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title
        v-if="contact.slContactType == 76">
            
            <a
            target="_blank"
            :href="'mailto:' + contact.Contact"
            >
                {{ contact.Contact }}
            </a>



        </v-list-item-title>
        <v-list-item-title
        v-if="contact.slContactType != 76">
            
            <span
            class="d-md-none mr-2">
                <a
                target="_blank"
                :href="'tel:+7' + contact.extracted_phone"
                >
                    +7{{ contact.extracted_phone }}
                </a>
            </span>
            <span
            class="d-none d-md-inline mr-2">
                +7{{ contact.extracted_phone }}
            </span>
            <span
            class="mr-2">
                <a
                target="_blank"
                :href="'whatsapp://send/?phone=7' + contact.extracted_phone"
                >
                <v-icon
                color="green darken-1">
                    mdi-whatsapp
                </v-icon>
                </a>
            </span>
            <span
            class="mr-2">
                <a
                target="_blank"
                :href="'https://t.me/+7' + contact.extracted_phone"
                >
                <v-icon
                color="light-blue accent-4">
                    mdi-navigation-variant
                </v-icon>
                </a>
            </span>



        </v-list-item-title>
        <v-list-item-subtitle>{{ contact.About }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>


</template>


<script>
export default {
    name: 'contact-row',
    props: {
        contact: {
            type: Object
        },
    },
    data () {
      return {}
    },
    created() {
    }
}
</script>