<template>
    <v-menu
    v-if="square.ID"
    open-on-hover
    bottom
    offset-y
    >

        <template v-slot:activator="slotData">
            <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotData" />
            <v-chip
            v-else
            v-on="slotData.on"
            :color="color"
            class="mr-2"> 
                <v-icon>
                    mdi-seat
                </v-icon> 
            </v-chip>
        </template>

        <v-list>
            <v-list-item
            target="_blank"
            :href="'/object/' + square.ID">
                <v-list-item-title>
                    <v-icon left>
                        mdi-link-variant
                    </v-icon>
                    Помещение № {{square.ID}}
                </v-list-item-title>
            </v-list-item>

            <v-list-item
            target="_blank"
            :href="'http://megapolis.mega-realty.ru/proxy.htm?type=square&id=' + square.ID">
                <v-list-item-title>
                    <v-icon left>
                        mdi-link-variant
                    </v-icon>
                    База Мегаполис
                </v-list-item-title>
            </v-list-item>

            <v-list-item
            target="_blank"
            :href="'https://www.mega-realty.ru/square_redirect.htm?id=' + square.ID">
                <v-list-item-title>
                    <v-icon left>
                        mdi-web
                    </v-icon>
                    mega-realty.ru
                </v-list-item-title>
            </v-list-item>

            <v-list-item
            target="_blank"
            :href="'/object-uploads/object/' + square.ID">
                <v-list-item-title>
                    <v-icon left>
                        mdi-camera-outline
                    </v-icon>
                    Загрузки
                </v-list-item-title>
            </v-list-item>

            <v-list-item @click="openAddPhotoDialog">
                <v-list-item-title>
                    <v-icon left>
                        mdi-camera-plus-outline
                    </v-icon>
                    Добавить фото
                </v-list-item-title>
            </v-list-item>
            <entity-dialog
            max-width="500px"
            :id="square.ID"
            type="square"
            :show="showAddPhotoDialog"
            @close-dialog="showAddPhotoDialog = false">
                <template v-slot:default>
                    <media-upload
                    :id="square.ID"
                    type="square"
                    @upload-complete="showAddPhotoDialog = false"/>
                </template>
            </entity-dialog>

            <v-list-item @click="openYoutubeDialog">
                <v-list-item-title>
                    <v-icon left>
                        mdi-youtube
                    </v-icon>
                    YouTube
                </v-list-item-title>
            </v-list-item>
            <entity-dialog
            max-width="900px"
            :id="square.ID"
            type="square"
            :show="showYoutubeDialog"
            @close-dialog="showYoutubeDialog = false">
                <template v-slot:default>
                    <youtube-links
                    :id="square.ID"
                    type="square"/>
                </template>
            </entity-dialog>

            <v-list-item
            v-if="supplementaryPermission"
            @click="openSupplementaryDialog">
                <v-list-item-title>
                    <v-icon left>
                        mdi-text-long
                    </v-icon>
                    Публикуемое описание
                </v-list-item-title>
            </v-list-item>

            <v-list-item @click="openChangesLogDialog">
                <v-list-item-title>
                    <v-icon left>
                        mdi-script-text-outline
                    </v-icon>
                    Лог изменений
                </v-list-item-title>
            </v-list-item>
            <entity-dialog
            :id="square.ID"
            type="square"
            :show="showChangesLogDialog"
            @close-dialog="showChangesLogDialog = false">
                <template v-slot:default>
                    <square-change-log :square-id="square.ID"/>
                </template>
            </entity-dialog>

        </v-list>
    </v-menu>
</template>

<script>
    import entityDialog from '@/components/entity-dialog.vue'
    import squareChangeLog from '@/components/entities/square/square-change-log.vue'
    import youtubeLinks from '@/components/youtube-links.vue'
    import mediaUpload from '@/components/media-upload.vue'

    export default {
        name: 'square-main-menu',
        components: {
            entityDialog,
            squareChangeLog,
            youtubeLinks,
            mediaUpload,
        },
        props: {
            square: {
                type: Object,
            },
        },
        data () {
            return {
                showChangesLogDialog: false,
                showAddPhotoDialog: false,
                showYoutubeDialog: false,
            }
        },
        computed: {
            color: function () {
                let result = 'default';
                if ( this.square.slSquareState ) {
                    if ( this.square.slSquareState === 110 ) {
                        result = 'green';
                    } else if ( this.square.slSquareState === 111 ) {
                        result = 'red';
                    } else if ( this.square.slSquareState === 113 ) {
                        result = 'orange';
                    } else {
                        result = 'default';
                    }
                }
                return result;
            },
            supplementaryPermission: function () {
                return (this.square.User_ID == this.$store.state.user.id || this.$checkPermissions('edit_Third-Party_Supplementaries')); 
            },

        },
        methods: {
            openAddPhotoDialog () {
                setTimeout(() => this.showAddPhotoDialog = true);
            },
            openYoutubeDialog () {
                setTimeout(() => this.showYoutubeDialog = true);
            },
            openChangesLogDialog () {
                setTimeout(() => this.showChangesLogDialog = true);
            },
            openSupplementaryDialog () {
                this.$emit('open-supplementary-editor');
            },
            dataChanged () {
                this.$emit('on-change-data');
            }
        },
    }
</script>