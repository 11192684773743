<template>
    <v-container>

        <search-by-id
        :form-data="searchFormData"
        :do-request="doOfferSearch"
        @loading-complete="doOfferSearch=false"
        @refresh-data="doOfferSearch=true"
        />

    </v-container>
</template>

<script>
import searchById from '@/components/search-by-id.vue'

export default {
    name: 'idSearch',
    components: { 
      searchById,
    },
    data () {
      return {
        doOfferSearch: true,
      }
    },
    computed: {
        currentId: function() {
            return (+this.$route.params.id || undefined)
        },
        searchFormData: function() {
            return {
                id: this.currentId,
                type: 'offer'
            }
        },
        offerSearchParams: function() {
            return {
                square_id: this.currentId,
            }
        },
    },
}
</script>