<template>
    <search-page
    :counter="counter"
    @clear-search-form="clearForm">

        <template v-slot:header>Действия</template>

        <template v-slot:search-form>
           <offer-search-form
            :params="params.searchParams"
            @do-search="formDoSearch"/>
        </template>

        <template v-slot:sorter>
            <search-sorter
            :options="fields.order.options"
            :order="params.searchParams.order"
            @change-order="changeOrder"
            />
        </template>

        <template v-slot:search-chips>
            <offer-search-chips
            :params="params.searchParams"
            @setFields="setFields"/>
        </template>

        <template v-slot:data-loader>
            <offers-list
            :do-request="doRequest"
            :search-params="params.searchParams"
            @loading-complete="doRequest=false"
            />
        </template>

    </search-page>
</template>

<script>
import searchParamsClass from '@/components/js/searchParams.js'
import offerSearchForm from './offer-search-form.vue'
import {offerSearchOptions} from '@/components/js/searchOptions.js'
import searchPage from './search-page.vue'
import searchSorter from './search-sorter.vue'
import offerSearchChips from "./offer-search-chips.vue"
import searchPageMixin from '@/mixins/search-page-mixin.js'
import offersList from './offers-list.vue'

export default {
    name: 'offer-search',
    components: { 
        searchPage,
        offerSearchForm,
        offerSearchChips,
        searchSorter,
        offersList,
    },
    mixins: [
        searchPageMixin
    ],
    data () {
        return {
            fields: offerSearchOptions,
            params: new searchParamsClass(offerSearchOptions),
        }
    },

}
</script>