<template>
    <v-menu
    v-if="firmId"
    open-on-hover
    bottom
    offset-y
    >

        <template v-slot:activator="slotData">
            <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotData" />
            <v-chip
            v-else
            v-on="slotData.on"
            class="mr-2"> 
                <v-icon>
                    mdi-account-question
                </v-icon>
            </v-chip>
        </template>

        <v-list>

            <v-list-item>
                <v-list-item-title>
                    Фирма № {{firmId}}
                </v-list-item-title>
            </v-list-item>


            <v-list-item
            target="_blank"
            :href="'http://megapolis.mega-realty.ru/proxy.htm?type=firm&id=' + firmId">
                <v-list-item-title>
                    <v-icon left>
                        mdi-link-variant
                    </v-icon>
                    База Мегаполис
                </v-list-item-title>
            </v-list-item>

            <v-list-item
            target="_blank"
            :href="'http://web-server.megapolis.local/CreDocument.aspx?type=firm&id=' + firmId">
                <v-list-item-title>
                    <v-icon left>
                        mdi-file-document-multiple-outline
                    </v-icon>
                    Документы
                </v-list-item-title>
            </v-list-item>

            <!--
            <v-list-item
            target="_blank"
            :href="'http://web-server.megapolis.local/CreRequestMailView.aspx?firm=' + firmId">
                <v-list-item-title>
                    <v-icon left>
                        mdi-email-multiple-outline
                    </v-icon>
                    Письма
                </v-list-item-title>
            </v-list-item>
            -->

             <v-list-item
            target="_blank"
            :href="'http://web-server.megapolis.local/EstateSingleModule.aspx?mode=contract&firmId=' + firmId">
                <v-list-item-title>
                    <v-icon left>
                        mdi-file-document-edit-outline
                    </v-icon>
                    Договоры
                </v-list-item-title>
            </v-list-item>

             <v-list-item
            target="_blank"
            :href="'http://web-server.megapolis.local/views/firmcommunication.aspx?firmId=' + firmId">
                <v-list-item-title>
                    <v-icon left>
                        mdi-phone-log
                    </v-icon>
                    Журнал общения
                </v-list-item-title>
            </v-list-item>

        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: 'firm-main-menu',
        props: {
            firmId: {
                type: Number,
            },
        },
    }
</script>