<template>
    <v-list flat>
    <template v-for="item in menuItems">
        <v-list-group
        v-if="item.children"
        :key="item.text"
        v-model="item.model"
        :prepend-icon="item.icon"
        >
        <template v-slot:activator>
            <v-list-item-content>
            <v-list-item-title>
                {{ item.text }}
            </v-list-item-title>
            </v-list-item-content>
        </template>
        <v-list-item
            v-for="child in item.children"
            :key="child.text"
            :to="child.link"
            link
            class="ml-4 subtitle"
        >
            <v-list-item-action v-if="child.icon">
            <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
            <v-list-item-title>
                {{ child.text }}
            </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        </v-list-group>
        <v-list-item
        v-else
        :key="item.text"
        :to="item.link"
        link
        >
        <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>
            {{ item.text }}
            </v-list-item-title>
        </v-list-item-content>
        </v-list-item>
    </template>
    </v-list>
</template>

<script>


export default {
    name: 'main-menu',
    components: { 
      
    },
    computed: {
        mainMenu: function() {
            return [
                { icon: 'mdi-biohazard', text: 'Test', link:'/test-page', permissionKey: 'view_Test_Pages'},
                {
                    icon: 'mdi-magnify',
                    text: 'Поиск по ID',
                    link:'/id-search',
                },
                {
                    icon: 'mdi-camera-outline',
                    text: 'Фотографии',
                    model: false,
                    children: [
                        { icon: 'mdi-camera-plus', text: 'Загрузить файлы', link:'/file-upload'},
                        { icon: 'mdi-image-search', text: 'Архив загрузок', link:'/media-uploads'},
                        { icon: 'mdi-office-building-marker', text: 'Здания на карте', link:'/building-foto-map'},
                    ],
                },
                { icon: 'mdi-check-decagram-outline', text: 'Бонусы', link:'/favorites'},
                {
                    icon: 'mdi-chevron-up-box-outline',
                    text: 'Циан',
                    model: false,
                    children: [
                        { icon: 'mdi-clock-alert-outline', text: 'Циан новые', link:'/cian-new-offers'},
                        { icon: 'mdi-playlist-edit', text: 'Циан фид', link:'/cian-ad-feed', permissionKey: 'edit_Ad_Feed'},
                        { icon: 'mdi-playlist-edit', text: 'Циан архив', link:'/cian-ad-archive', permissionKey: 'edit_Ad_Feed'},
                        { icon: 'mdi-playlist-check', text: 'Лог проверки', link:'/cian-ad-checker-log', permissionKey: 'edit_Ad_Feed'},
                    //  { icon: 'mdi-account-clock', text: 'Новые аккаунты', link:'/cian-new-accounts'},
                    ],
                },


                {
                    icon: 'mdi-youtube',
                    text: 'YouTube',
                    link:'/youtube-links'
                },
                {
                    icon: 'mdi-shield-key-outline',
                    text: 'Ключи доступа',
                    link:'/access-keys'
                },
                {
                    icon: 'mdi-map-marker-outline',
                    text: 'Адреса',
                    model: false,
                    children: [
                        { icon: 'mdi-sign-direction', text: 'Улицы', link:'/street-names'},
                        { icon: 'mdi-city-variant-outline', text: 'Города', link:'/city-names'},
                        { icon: 'mdi-map-marker-alert-outline', text: 'Ошибки', link:'/broken-address'},
                    ],
                },
                {
                    icon: 'mdi-chat-question-outline',
                    text: 'Заявки',
                    model: false,
                    children: [
                        { icon: 'mdi-chat-question-outline', text: 'Поиск заявок', link: { path: '/request-search', query: { user_id: this.$store.state.user.id, status: 3, last_change_date_from: 180 } } },
                        { icon: 'mdi-chat-question-outline', text: 'Прозвон', link: { path: '/request-search', query: { time_to_call: 1 } }, permissionKey: 'view_Third-Party_Request'},
                    ],
                },
                {
                    icon: 'mdi-text-box-outline',
                    text: 'Журналы',
                    model: false,
                    children: [
                        { icon: 'mdi-text-box-check-outline', text: 'Журнал действий', link: { path: '/offer-search', query: { date_from: 30 } } },
                    ],
                }
            ]
        }
    },


    data () {
        return {
            menuItems: [],
        }
    },
    methods: {
        createMenu: function () {
            
            const chk = (obj) => (!obj.permissionKey || this.$checkPermissions(obj.permissionKey));

            this.menuItems = [];

            this.mainMenu.forEach( item => {

                if ( chk(item) ) {
                    let tmp = {
                        icon: item.icon,
                        text: item.text,
                        model: item.model,
                        link: item.link,
                    };

                    if ( item.children ) {
                        tmp.children = [];
                        item.children.forEach( child => {

                            if ( chk(child) ) {
                                tmp.children.push(child)
                            }

                        })
                    }
                    this.menuItems.push(tmp);
                }
            })
        },
    },
    created() {
        this.$eventBus.$on('on-app-init-complete', this.createMenu);
    }
}
</script>

<style>

</style>