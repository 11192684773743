<template>
    <v-menu
        left
        offset-y
        :close-on-content-click="false"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-chip
            color="teal accent-4"
            v-bind="attrs"
            v-on="on"
            >
                <slot>
                    <v-icon class="mr-2">
                        mdi-phone
                    </v-icon>
                    Контакты
                </slot>
            </v-chip>
        </template>

            <simple-data-loader
            url="/api/firm/contacts"
            :form-data="{firm_id: firmId}"
            :check-response="checkResponse"
            @loading-complete="onLoadData">
                <template v-slot:default>
                    <v-list>
                        <contact-row
                        v-for="item in contacts"
                        :key="item.ID"
                        :contact="item"
                        />
                    </v-list>
                </template>
            </simple-data-loader>
    </v-menu>
</template>

<script>
import simpleDataLoader from '@/components/simple-data-loader.vue'
import contactRow from '@/components/contact-row.vue'

export default {
    name: 'firm-contacts-list',
    components: {
        contactRow,
        simpleDataLoader,
    },
    props: {
        firmId: {
            type: Number,
        },
    },
    data () {
        return {
            checkResponse: function (response) {
                return (response.contacts && response.contacts.length > 0);
            },
        }
    },
    methods: {
        onLoadData: function (data) {
            this.contacts = [];
            if ( data && this.checkResponse(data) ) {
                this.contacts = data.contacts;
            }
        },
    },
}
</script>