<template>
  <v-container>
    <v-row>
        <v-col>
            <div
            v-if="square.About"
            class="text--secondary">
                {{square.About}}
            </div>

            <v-alert
            class="mt-4"
            v-if="square.supplementary"
            border="top"
            colored-border
            type="info"
            icon="mdi-text-long"
            elevation="2"
            >
                {{square.supplementary}}
            </v-alert>

            <div class="mt-2">
                <date-ins-change-del-tooltip time :item="square"/>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import dateInsChangeDelTooltip from '@/components/date-ins-change-del-tooltip.vue'
    export default {
        name: 'square-supplementary',
        components: {
            dateInsChangeDelTooltip,
        },
        props: {
            square: {
                type: Object,
            },
        },
        data () {
            return {}
        },
    }
</script>

