<template>
    <v-tooltip right color="primary">
        <template v-slot:activator="{ on, attrs }">
            <span
                v-bind="attrs"
                v-on="on"
                class="text-caption grey--text text--darken-1">
                <slot>
                    <date-sql-date-formatter :date="item.DateUpd" :shortdate="short"/>
                    <staff-name v-if="!dateOnly" :id='item.User_Upd'/>
                </slot>
            </span>
        </template>
        <p
        class="my-0"
        v-if="item.User_Ins">
            Ins:
            <date-sql-date-formatter time :date="item.DateIns"/>
            <staff-name :id='item.User_Ins'/>
        </p>
        <p
        class="my-0"
        v-if="item.User_Upd">
            Upd: 
            <date-sql-date-formatter time :date="item.DateUpd"/>
            <staff-name :id='item.User_Upd'/>
        </p>
        <p
        class="my-0"
        v-if="item.User_Del">
            Del: 
            <date-sql-date-formatter time :date="item.DateDel"/>
            <staff-name :id='item.User_Del'/>
        </p>
    </v-tooltip>
</template>

<script>
    import staffName from '@/components/staff-name.vue'
    import dateSqlDateFormatter from '@/components/date-sql-date-formatter.vue'

    export default {
        name: 'date-ins-change-del-tooltip',
        components: {
            staffName,
            dateSqlDateFormatter,
        },
        props: {
            item: {
                type: Object,
            },
            short: {
                type: Boolean,
                default: false,
            },
            dateOnly: {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {}
        },
    }
</script>

<style>
</style>