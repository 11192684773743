<template>
    <v-tooltip
    bottom
    color="cyan"
    v-if="caption">
        <template v-slot:activator="{ on, attrs }">
            <v-chip
            class="my-2"
            color="cyan"
            v-bind="attrs"
            v-on="on"
            small
            >
                {{caption}}
            </v-chip>
        </template>
        {{stationString}}
    </v-tooltip>


</template>

<script>
import MetroCheckboxes from '@/components/js/MetroCheckboxes.js'
import declineNoun from '@/mixins/decline-nouns-after-num.js'
export default {
    name: 'metro-stations-list',
    props: {
        mask: {
            type: String
        },
    },
    mixins: [declineNoun],
    computed: {
        caption: function() {
            let result = ''
            if (this.mask == '0x082400000498A84405C5014481100800020004') {
                result = "Центр"
            } else if (this.mask == '0x0A3C00401598BC5C05C50345951C6C82120004') {
                result = "До ТТК"
            } else if( this.stations.length == 0) {
                result = ''
            } else if( this.stations.length > 3) {
                result = this.stations.length + this.declineNouns(this.stations.length, ' станция', ' станции', ' станций') + ' метро';
            } else {
                result = [].concat( ...this.stations ).join(', ')
            }

            return result;
        },
       stationString: function() {
            if( this.stations.length == 0) {
                return ''
            } else {
                return [].concat( ...this.stations ).join(', ')
            }
         },
        stations: function() {
            let selectedCheckboxes = new MetroCheckboxes(this.mask);
            let stationList = this.$store.state.metroCheckboxes;
            let result = [];
            if(selectedCheckboxes && selectedCheckboxes.list && selectedCheckboxes.list.length > 0){
                selectedCheckboxes.list.forEach(function(item) {
                    if(stationList[item]){
                        stationList[item].forEach(function(obj) {
                            result.push(obj.name);
                        })
                        
                    }
                })
            }
            return result;
        },
    },


}
</script>
