<template>

    <v-row
    class="offers-row pl-1"
    >
        <v-col
        cols="2"
        sm="1"
        class="text-right"
        >
            <a target="_blank" :href="'https://www.cian.ru/rent/commercial/' + offer.id + '/'">{{ offer.total_area }} м<sup>2</sup></a>

        </v-col>
        <v-col
        cols="2"
        md="1"
        class="text-right"
        >
            {{ floor }}
        </v-col>
        <v-col
        cols="4"
        sm="2"
        xl="1"
        class="text-right"
        >
            {{ price }}
        </v-col>
        <v-col
        cols="3"
        sm="2"
        xl="1"
        class="text-right text-sm-left"
        >
            {{ type }}

        </v-col>
        <v-col
        cols="1"
        class="text-center"
        >


            <v-menu
                open-on-hover
                top
                offset-y
                :disabled = "disabledSqMenu"
                >
                <template v-slot:activator="{ on, attrs }">

                    <v-icon
                    small
                    :color = "matchedColor"
                    v-bind="attrs"
                    v-on="on"
                    >
                        mdi-information
                    </v-icon>
                </template>

                <v-simple-table>
                    <template v-slot:default>
                    <tbody>
                        <tr
                        v-for="item in offer.megapolis_objects"
                        :key="item.sq_id"
                        >
                            <td>
                                
                                <a
                                target="_blank"
                                :href="'http://megapolis.mega-realty.ru/proxy.htm?type=square&id=' + item.sq_id"
                                >
                                    ID {{ item.sq_id }}
                                </a>
                            </td>
                            <td>{{ item.Square }} м<sup>2</sup></td>
                            <td>{{ item.Floor }} этаж</td>
                            <td>{{ numberFormat(item.price) }} р.</td>
                            <td>{{ item.Fam }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>

            </v-menu>


        </v-col>


        <v-col
        cols="12"
        sm="4"
        md="5"
        xl="7"
        class="text-right text-sm-left"
        >


            <a
            target="_blank"
            :href="'https://www.cian.ru/cat.php?deal_type=rent&engine_version=2&id_user=' + offer.main_user_id + '&offer_type=offices'">
                {{ offer.main_company_name || offer.main_user_id}}
            </a>

            ({{offer.user_offers_count}}/{{offer.user_buildings_count}})

            <v-chip
            v-if="offer.user_age < 30"
            class="ms-2"
            color="green"
            text-color="white"
            small
            >
                {{offer.user_age}} дн.
            </v-chip>

            <a @click="hideAgent({id: offer.main_user_id, name: offer.main_company_name || offer.main_user_id})" >
                <v-icon small class="ml-2" title="Скрыть предложения агентства">mdi-eye-off</v-icon>
            </a>

        </v-col>
        <v-spacer></v-spacer>

    </v-row>

</template>


<style scoped>
.offers-row {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}

.offers-row:last-of-type {
  border-bottom: none;
}
</style>



<script>

export default {
    name: 'cianNewOffersOffer',
    props: {
        offer: {
            type: Object
        },
    },
    data () {
      return {}
    },
    computed: {
        floor: function () {
            if (this.offer.floor_number == -2) {
                return 'подвал';
            } else if (this.offer.floor_number == -1) {
                return 'цоколь';
            } if (this.offer.floor_number > 0) {
                return this.offer.floor_number;
            } else {
                return '';
            }
        },
        price: function () {
            return new Intl.NumberFormat().format(this.offer.price) + ' р.';
        },
        type: function () {
            if (this.offer.office_type == 'office') {
                return 'офис';
            } else if (this.offer.office_type == 'building') {
                return 'здание';
            } else if (this.offer.office_type == 'freeAppointment') {
                return 'ПСН';
            } else if (this.offer.office_type == 'warehouse') {
                return 'склад';
            } else if (this.offer.office_type == 'floorSpace') {
                return 'торговля';
            } else if(this.offer.office_type == 'production') {
                return 'производство';
            } else {
                return this.offer.office_type;
            }
        },
        disabledSqMenu: function () {
            return (!this.offer.megapolis_objects.length > 0);
        },
        matchedColor: function() {
            if(this.offer.exact_match) {
                return 'green';
            } else if (this.offer.megapolis_objects && this.offer.megapolis_objects.length > 0) {
                return 'amber';
            } else {
                return 'red darken-1';
            }
        }
    },
    methods: {
        numberFormat: function (n) {
            return new Intl.NumberFormat().format(n);
        },
        hideAgent: function (obj) {
            this.$eventBus.$emit('hide-agency-from-cian-offers-list', obj);
        }
    },
}
</script>