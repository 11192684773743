<template>
    <v-dialog
    v-model="visible"
    v-bind="$attrs"
    >
        <v-overlay
        :value="loading"
        :absolute="true">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
        <v-toolbar
        color="primary"
        dark
        >
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
            icon
            @click="visible = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-card-text class="pt-8 pb-0">
                <slot/>
            </v-card-text>
            <v-card-actions class="pa-6">
                <v-row>
                    <v-col
                    cols="12"
                    sm="3"
                    class="text-center text-sm-left"
                    >
                        <v-btn
                        v-if="delCondition"
                        small
                        elevation="2"
                        @click="deleteData"
                        >
                        <v-icon>mdi-delete-forever</v-icon> Удалить
                        </v-btn>
                    </v-col>
                    <v-col
                    cols="12"
                    sm="9"
                    class="text-center text-sm-right"
                    >
                        <v-btn
                        color="gray"
                        elevation="2"
                        class="mx-8 mb-4 mb-sm-0 mr-sm-0 ml-sm-8"
                        @click="closeDialog"
                        >
                        Отменить
                        </v-btn>
                        <v-btn
                        color="primary"
                        :disabled="!saveCondition"
                        x-large
                        class="mx-8 mr-sm-0 ml-sm-8"
                        @click="updateData"
                        >
                        Сохранить
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'data-editor',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Редактирование'
        },
        newItem: {
            type: Boolean,
            default: false
        },
        delCondition: {
            type: Boolean,
            default: true
        },
        saveCondition: {
            type: Boolean,
            default: true
        },
        url: {
            type: String
        },
        formData: {
            type: Object
        },
    },
    data () {
        return {
            loading: false,
        }
    },
    computed: {
        visible: {
            get () {
                return this.show;
            },
            set (value) {
                if(!value){
                    this.$emit('close-dialog');
                }
            }
        }
    },
    methods: {
        updateData: function () {
            if (this.newItem) {
                this.apiRequest({
                    path: '/add',
                    msgSuccess: 'Запись успешно добавлена',
                    msgDecline: 'Запись не была добавлена',
                    msgError: 'Ошибка при добавлении записи',
                });
            } else {
                this.apiRequest({
                    path: '/update',
                    msgSuccess: 'Данные успешно изменены',
                    msgDecline: 'Данные не были изменены',
                    msgError: 'Ошибка при изменении данных',
                });
            }

        },
        deleteData: function () {
            if ( confirm("Точно удалить?") ) {
                this.apiRequest({
                    path: '/delete',
                    msgSuccess: 'Запись успешно удалена',
                    msgDecline: 'Запись не была удалена',
                    msgError: 'Ошибка при удалении записи',
                });
            }
        },
        closeDialog: function () {
            this.$emit('close-dialog');
        },
        apiRequest: function (config) {
            this.loading = true;
            if (this.url && this.formData) {
                this.$http({url: this.url + config.path, method: 'POST', data: this.formData})
                .then(
                    response => {
                        this.loading = false;
                        this.$emit('close-dialog');
                        this.$emit('request-complete', response);
                        if ( response.success ) {
                            this.$eventBus.$emit('on-get-status', true, config.msgSuccess);
                        } else {
                            this.$eventBus.$emit('on-get-status', false, config.msgDecline);
                        }
                        
                    },
                    () => {
                        this.loading = false;
                        this.$emit('close-dialog');
                        this.$eventBus.$emit('on-get-status', false, config.msgError);
                        this.$emit('request-complete');
                    }
                )
            }
        },
    },
}
</script>
