<template>
    <v-container>

        <data-loader
        url="/api/search/by-id/"
        :form-data="formData"
        :do-request="doRequest"
        :check-response="checkResponse"
        @loading-complete="onLoadData">
            <template v-slot:default>

                <data-viewer
                :dataObject="searchResults"
                @refresh-data="doRefreshData"
                />

            </template>
        </data-loader>

    </v-container>
</template>

<script>
import dataLoader from '@/components/data-loader.vue'
import dataViewer from '@/components/data-viewer.vue'

export default {
    name: 'search-by-id',
    components: { 
      dataLoader,
      dataViewer
    },
    props: {
        formData: {
            type: Object,
        },
        doRequest: {
            type: Boolean
        },
    },
    data () {
      return {
        searchResults: {},
      }
    },
    methods: {
        checkResponse: function (data) {
            return (data && (data.building || data.square || data.request));
        },
        onLoadData: function (data) {
            this.searchResults = data,
            this.$emit('loading-complete');
        },
        doRefreshData: function (){
            this.$emit('refresh-data');
        }
    },

}
</script>