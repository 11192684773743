<template>
    <v-container class="pa-6">
        <v-row
        v-if="firm.ID">
            <v-col
            cols="12"
            sm="6"
            lg="4">
                <firm-main-menu
                :firmId="firm.ID"/>

                <span
                v-if="!firm.Name">
                    Фирма {{firm.ID}}
                </span>

                {{firm.Name}}
            </v-col>

            <v-col
            cols="12"
            sm="6"
            lg="3"
            class="order-lg-last">
                <firm-contacts-list
                :firmId="firm.ID"/>
            </v-col>

            <v-col
            cols="12"
            lg="5">
                
                <div v-if="firm.About"
                class="text-caption grey--text text--darken-1 word-break-normal">
                    {{firm.About}}
                </div>
            </v-col>

        </v-row>
    </v-container>

</template>

<script>
import firmMainMenu from '@/components/entities/firm/firm-main-menu.vue'
import firmContactsList from '@/components/entities/firm/firm-contacts-list.vue'
    export default {
        name: 'firm-heading',
        components: {
            firmMainMenu,
            firmContactsList
        },
        props: {
            firm: {
                type: Object,
            },
        },
    }
</script>
