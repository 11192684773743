<template>
    <v-container>
        <v-row
        v-if="squareObject.ID">
            <v-col
            cols="12"
            sm="10"
            class="d-flex align-center">

                <square-main-menu
                :square="squareObject"
                @open-supplementary-editor="showSupplementaryEditor = true"/>


                <span>
                    {{squareObject.shortTitle}}
                </span>
            </v-col>
            <v-col
            cols="12"
            sm="2"
            class="text-caption grey--text"
            >
                <staff-name :id='squareObject.User_ID'/>
            </v-col>
        </v-row>

        <square-supplementary-editor
        :square="squareObject"
        :show="showSupplementaryEditor"
        @on-change-data="refreshData"
        @close-dialog="showSupplementaryEditor = false">
        </square-supplementary-editor>

    </v-container>
</template>

<script>
import Square from '@/components/js/square.js'
import squareMainMenu from '@/components/entities/square/square-main-menu.vue'
import staffName from '@/components/staff-name.vue'
import squareSupplementaryEditor from '@/components/entities/square/square-supplementary-editor.vue'

    export default {
        name: 'square-heading',
        components: {
            squareMainMenu,
            staffName,
            squareSupplementaryEditor,
        },
        props: {
            square: {
                type: Object,
            },
        },
        data () {
            return {
                squareObject: {},
                showSupplementaryEditor: false
            }
        },
        methods: {
            refreshData: function () {
                this.$emit('refresh-data');
            },
        },
        created() {
            this.squareObject = new Square(this.square);
        }
    }
</script>
