<template>
    <v-menu
    v-if="request.ID"
    open-on-hover
    bottom
    offset-y
    >
        <template v-slot:activator="slotData">
            <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotData" />
            <v-chip
            v-else
            v-on="slotData.on"
            :color="color"
            class="mr-2"> 
                <v-icon>
                    mdi-chat-question-outline
                </v-icon>
                <!-- {{request.ID}} -->     
            </v-chip>
        </template>
        
        <v-list>

            <v-list-item
            target="_blank"
            :href="'/request/' + request.ID">
                <v-list-item-title>
                    <v-icon left>
                        mdi-link-variant
                    </v-icon>
                    Заявка № {{request.ID}}
                </v-list-item-title>
            </v-list-item>

            <v-list-item
            target="_blank"
            :href="'http://megapolis.mega-realty.ru/proxy.htm?type=request&id=' + request.ID">
                <v-list-item-title>
                    <v-icon left>
                        mdi-link-variant
                    </v-icon>
                    База Мегаполис
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: 'request-main-menu',
        props: {
            request: {
                type: Object,
            }
        },
        computed: {
            color: function () {
                let result = 'default';
                if ( this.request.slRequestState ) {
                    if ( this.request.slRequestState === 114 ) {
                        result = 'green';
                    } else if ( this.request.slRequestState === 115 ) {
                        result = 'red';
                    } else if ( this.request.slRequestState === 116 ) {
                        result = 'orange';
                    } else {
                        result = 'default';
                    }
                }
                return result;
            },
        }
    }
</script>